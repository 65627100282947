import {NameInOther, Severity, Violation} from '@wix/wixstores-graphql-schema-node';

export const calcTopThreeViolations = (violations: Violation[]): Violation[] => {
  return violations?.length > 0
    ? [
        ...violations.filter(
          (violation) =>
            violation.severity === Severity.ERROR && violation.target?.other?.name === NameInOther.OTHER_DEFAULT
        ),
        ...violations.filter(
          (violation) =>
            violation.severity === Severity.WARNING && violation.target?.other?.name === NameInOther.OTHER_DEFAULT
        ),
      ]
        .slice(0, 3)
        .reverse()
    : [];
};
